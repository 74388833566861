// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aboutus-index-js": () => import("./../../../src/pages/aboutus/index.js" /* webpackChunkName: "component---src-pages-aboutus-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-uwu-2-js": () => import("./../../../src/pages/blog/uwu2.js" /* webpackChunkName: "component---src-pages-blog-uwu-2-js" */),
  "component---src-pages-blog-uwu-3-js": () => import("./../../../src/pages/blog/uwu3.js" /* webpackChunkName: "component---src-pages-blog-uwu-3-js" */),
  "component---src-pages-blog-where-it-all-begins-js": () => import("./../../../src/pages/blog/where-it-all-begins.js" /* webpackChunkName: "component---src-pages-blog-where-it-all-begins-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-discography-index-js": () => import("./../../../src/pages/discography/index.js" /* webpackChunkName: "component---src-pages-discography-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

